import React from 'react';
import { Link } from "gatsby";
import moment from 'moment';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';


import './styles.scss';
import Layout from '../../components/layout';
import NewsletterSignupForm from '../../components/NewsletterSignupForm/index'

function BlogPost({ location, pageContext: { post } }) {
    const metaTitle = `${post.title} | Voa Labs`;

    return (
        <Layout location={location} showLanguageSelector={false}>
            <Helmet bodyAttributes={{ class: 'whiteHeader whiteHeader-show' }}>
                <title>{parse(metaTitle)}</title>
                <meta name="description" content={post.excerptStripped} />
                <meta property="og:title" content={parse(metaTitle)} />
                <meta property="og:description" content={post.excerptStripped} />
                <meta name="image" property="og:image" content={post.linkedinImage}></meta>
                <meta name="author" content="Joël Sonderegger"></meta>
            </Helmet>
            <article className="BlogPost--section">
                <div className="container">
                    <div className="columns is-desktop">
                        <div className="column is-1">
                            <Link to="/blog" className="BlogPost__back">
                                <div className="BlogPost__backIcon">
                                    <svg width="12" height="14" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.14629 0L0 4.99999L5.14458 10L5.75058 9.4125L1.64057 5.41666H12V4.58334H1.64057L5.75229 0.585827L5.14629 0Z" fill="#24262C" />
                                    </svg>
                                </div>
                                <div className="BlogPost__backText">
                                    Overview
                                </div>
                            </Link>
                        </div>
                        <div className="column">
                            <div className="BlogPost__header">
                                <h1>{parse(post.title)}</h1>
                                <div className="BlogPost__metaInfo">
                                    <figure className="image">
                                        <img className="is-rounded BlogPost__authorImage" src={post.authorImgUrl} alt="Author" />
                                    </figure>
                                    <div className="BlogPost__metaInfoText">
                                        <div className="BlogPost__authorName"> {post.author}</div>
                                        <div className="BlogPost__publishedDate">{moment(post.date).format('LL')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="BlogPost__headerImageWrapper">
                                <div className="BlogPost__headerImage" style={{ backgroundImage: `url(${post.featureImageUrl})` }}>
                                </div>
                            </div>
                            <div className="BlogPost__content">

                                {parse(post.content)}
                            </div>
                            {post.slug === 'product-opportunity-assessment' &&
                                <div className="container downloadSection">
                                    <div className="columns is-vcentered is-tablet">
                                        <img src="/img/ipadpro_poa2.png" alt="iPod Pro with Product Opportunity Assessment template" className="column is-5" />
                                        <div className="column is-7 downloadSection__text">
                                            <div style={{ fontWeight: '700', fontSize: '22px', lineHeight: '28px', marginBottom: '13px' }}>Download the Product Opportunity Assessment Template for free</div>
                                            <div style={{ marginBottom: '13px' }}>Enter your email to subscribe to our newsletter and get instant access to this free PDF.</div>
                                            <NewsletterSignupForm link="/product_opportunity_assessment_voa_labs.pdf" submitButtonLabel="Access PDF" />
                                        </div>
                                    </div>

                                </div>
                            }
                            <div className="BlogPost__aboutus">
                                <h2>About Voa Labs</h2>
                                <p>
                                    Voa Labs is a digital product studio that transforms ideas into incredible products. Partnering with everyone from tiny web startups to experienced enterprises to help them build digital products that are simple, beautiful and easy to use.
                                </p>
                            </div>
                        </div>
                        <div className="column is-1">
                        </div>
                    </div>
                </div>
            </article>
            <section className="BlogPost__footer">
                <div className="container">
                    <div className="columns is-tablet">
                        <div className="column is-1">
                        </div>
                        <div className="column is-5 BlogPost__next">
                            <div className="uptitle">WHAT'S NEXT</div>
                            <Link to={`/blog/${post.nextPost.slug}`}>
                                <h2 className="BlogPost__nextTitle">
                                    {parse(post.nextPost.title)}
                                </h2>
                                {post.nextPost.excerpt &&
                                    <p className="BlogPost__nextContent">
                                        {parse(post.nextPost.excerpt)}
                                    </p>
                                }
                            </Link>
                        </div>
                        <div className="column is-5 BlogPost__newsletterSignup">
                            <div>
                                <p className="small-copy margin-s">
                                    Stay in the loop with our quarterly newsletter
                                </p>
                                <NewsletterSignupForm />
                            </div>
                        </div>
                        <div className="column is-1">
                        </div>
                    </div>
                </div>
            </section>
        </Layout >
    );
}

export default BlogPost;